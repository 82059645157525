import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate, Link }  from 'gatsby'
import ds from '../theme'
import UnderstandTheDifference from '../components/UnderstandTheDifference'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from './../components/Div'
import MuiSelect from '../components/Dropdown'
import H3 from '../components/H3'
import H5 from './../components/H5'
import Subtitle2 from '../components/SubTitle2'
import Paragraph from '../components/Paragraph'
import Body2 from '../components/Body2'
import ContainedButton from '../components/ContainedButton'
import { btnBuyLicence } from '../components/styles/ContainedButton.styles'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H6 from '../components/H6'
import ImgServidoresExclusivos from '../images/dc-servers/servidores-exclusivos.svg'
import ImgMonitoramento from '../images/dc-servers/monitoramento.svg'
import ImgFlexibilidade from '../images/dc-servers/flexibilidade.svg'
import ImgPortalDeGerenciamento from '../images/dc-servers/portal.svg'
import ImgIpmi from '../images/dc-servers/ipmi.svg'
import ImgMonitoramentoTempo from '../images/dc-servers/tempo.svg'
import ImgInspecaoSeguranca from '../images/dc-servers/inspecao.svg'
import { css } from '@emotion/core'

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledPrice,
  header,
  // currency,
  listServer,
  listManager,
  item
} from '../components/styles/DedicatedServersPrice.styles'

const initialModals = {
  support: false,
  moreInfo: false
}

const NewConfDC = ({
  productName,
  productDescription,
  productBtn,
}) => {
  return (
    <div
      style={{
        padding: '40px 16px 40px 16px',
        backgroundColor: '#ffffff',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 8,
            width: 296,
            textAlign: 'center'
          }}
        >
          {productName}
        </H5>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '20px 0'
          }}
        />
        <Body2 
          style={{
            fontSize: 14, 
            marginLeft: 24,
            marginRight: 24,
            height: 294,
            textAlign: 'left',
            lineHeight: 2.5,
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
            whiteSpace: 'pre-wrap'
          }}>
          {productDescription}
        </Body2>
        <Link
          type='btnOutlinedSmall'
          to='/contatos'
          css={btnBuyLicence}
        >
          {productBtn}
        </Link>
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section css={styledPrice}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
            mb={30}
          >
            <Column
              width={[1]}
            >
              <H3>Servidores Dedicados</H3>
              <Paragraph>Seu servidor dedicado na HostDime. Mais performance e segurança. <br />Mais tecnologia e suporte de especialistas. No Brasil ou nos Estados Unidos.</Paragraph>
            </Column>
          </Row>
          <Row
            flexDirection={['column']}
            textAlign={['center']}
          >
            <Column
              width={[1]}
              mb={20}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <MuiSelect
                  options={[
                    {label: 'DC Nordeste - Brasil', value: '/servidores-dedicados-nordeste'},
                    {label: 'DC Orlando - USA', value: '/servidores-dedicados-orlando'},
                    {label: 'DC Outlet', value: '/servidores-dedicados-outlet'}
                  ]}
                  label='DC São Paulo - Brasil'
                  onChange={(value) => {
                    if (value.value) navigate(value.value)
                  }}
                  value={{label: 'DC São Paulo - Brasil', value: 0}}
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <Subtitle2
                  maxWidth={[200, '100%']}
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    textAlign: 'center'
                  }}
                >
                  Brasil ou Estados Unidos? 
                  <span
                    onClick={() => setModalVisibility('moreInfo')(true)}
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      marginLeft: 2,
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                  >
                    Entenda a diferença
                  </span>
                </Subtitle2>
              </div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row', 'row']}
            alignItems={'center'}
            mt={[30]}
          >
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', '100%', 328]}
              >
                <NewConfDC
                  productName='Single Xeon 4 Core E3-1230 v5 3.4Ghz w/HT'
                  productDescription={`16GB RAM\n120GB SSD e 1TB HDD\nCentOS\n1Gbps Porta de Rede\n60Mbps ~ 20TB Banda Mensal\n4 IP's Dedicados`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <NewConfDC
                  productName='Single Xeon 6-Core E-2136 3.3Ghz w/HT'
                  productDescription={`32GB RAM\n120GB SSD e 1TB HDD\nCentOS\n1Gbps Porta de Rede\n60Mbps ~ 20TB Banda Mensal\n4 IP's Dedicados`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
              order={[3]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <NewConfDC
                  productName='2x 8 Core Xeon E5-2450 v2 2.5Ghz w/HT'
                  productDescription={`64GB RAM\n2x 450GB SAS\nCentOS\n1Gbps Uplink\n60Mbps ~ 20TB Banda Mensal\n4 IP's Dedicados`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={'center'}
            pb={20}
          >
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <NewConfDC
                  productName='2x 10 Core Xeon E5-2660 v2 2.2Ghz'
                  productDescription={`128GB RAM\n2x 240GB SSD\nCentOS\n1Gbps Uplink\n60Mbps ~ 20TB Banda Mensal\n4 IP's Dedicados`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <NewConfDC
                  productName='2x 16 Core Xeon Silver 4216 2.1 Ghz w/HT'
                  productDescription={`256GB RAM\n2x 960GB SSD\nCentOS\n1Gbps Uplink\n60Mbps ~ 20TB Banda Mensal\n4 IP's Dedicados`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
            <Column
              mb={[30]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <NewConfDC
                  productName='2x 20 Core Xeon Gold 6230 2.1 Ghz w/HT '
                  productDescription={`256GB RAM\n2x 1.92TB SSD\nCentOS\n1Gbps Uplink\n60Mbps ~ 20TB Banda Mensal\n4 IP's Dedicados`}
                  productBtn='CONSULTE'
                />
              </Div>
            </Column>
          </Row>
          <Hr
            lineColor='lightPeriwinkle'
            maxWidth={[328, '100%', 1032, 1032]}
            style={{
              opacity: 0.5,
              margin: '0 auto'
            }}
          />
          <Row
            flexDirection={['column', 'row']}
            mt={40}
            mb={50}
          >
            <Column
              width={[1]}
            >            
              <H4 style={{ textAlign: 'center' }}>Hardwares fisicamente isolados, de<br /> uso exclusivo para sua empresa.</H4>
            </Column>
          </Row>
          <Row
            css={listServer}
            mb={49.5}
          >
            <Div
              alignItems={'center'}
              flexDirection={['column', 'column', 'row']}
              textAlign={'center'}
              maxWidth={['100%', 422, 1020]}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1]}
                mb={[40, 40, 0, 0]}
              >            
                <Div
                  alignItems={'center'}
                >
                  <img src={ImgServidoresExclusivos} />
                  <H6>Um ou mais servidores<br /> exclusivos para usa empresa</H6>
                </Div>
              </Column>
              <Column
                width={[1]}
                mb={[40, 40, 0, 0]}
              >            
                <Div
                  alignItems={'center'}
                >
                  <img src={ImgFlexibilidade} />
                  <H6>Flexibilidade na <br />escolha do hadware</H6>
                </Div>
              </Column>
              <Column
                width={[1]}
                mb={[0]}
              >            
                <Div
                  alignItems={'center'}
                >
                  <img src={ImgMonitoramento} />
                  <H6>Monitoramento<br />proativo 24x7x365</H6>
                </Div>
              </Column>
            </Div>
          </Row>
          <Hr
            lineColor='lightPeriwinkle'
            maxWidth={[328, '100%', 1032, 1032]}
            style={{
              opacity: 0.5,
              margin: '0 auto'
            }}
          />
          <Row
            mb={30}
            mt={49.5}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              maxWidth={['100%', 422, 860]}
              alignItems={'center'}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={[30, 30, 0, 0]}
              >
                <div css={listManager}>
                  <img src={ImgPortalDeGerenciamento} />
                  <H6>Portal de gerenciamento dos servidores intuitivo</H6>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={listManager}>
                  <img src={ImgIpmi} />
                  <H6>IPMI gerencie seu hardware remotamente (BIOS)</H6>
                </div>
              </Column>
            </Div>
          </Row>
          <Row
            mb={[50, 70]}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              maxWidth={['100%', 422, 860]}
              alignItems={'center'}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={[30, 30, 0, 0]}
              >
                <div css={listManager}>
                  <img src={ImgMonitoramentoTempo} />
                  <H6>Monitoramento em<br /> tempo real</H6>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={listManager}>
                  <img src={ImgInspecaoSeguranca} />
                  <H6>Inspeção de segurança em 5 etapas</H6>
                </div>
              </Column>
            </Div>
          </Row>
          <Div
            alignItems={'center'}
          >
            <ContainedButton
              type='btnContainedLarge'
              Link='/gerenciamento-dedicado'
            >
              gerenciamento dedicado
            </ContainedButton>
          </Div>
        </Container>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Preços | Servidores Dedicados em São Paulo'
        description='Aproveite o melhor custo-benefício e garanta seu servidor dedicado em São Paulo, com a melhor tecnologia e suporte 24h de especialistas.'
      />
      <PageTemplate
        title='Preços Dedicados DC São Paulo - Brasil'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
